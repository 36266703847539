import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import styles from "./ProductDetail.module.css"; // import CSS file
import "./ProductDetail.module.css";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { addToCart, getCart } from "../../actions/cartAction";
import { useNavigate, useLocation } from "react-router-dom";
import Cart from "../Cart";
import Swatch from "./Swatch";
import "./swatches.css"; // import CSS file

import preOrderMark from "../../images/preorder.svg";
import { detailProduct } from "../../actions/productActions";
import Star from "../Helpers/icons/Star";
import InputCom from "../Helpers/InputCom";
import Selectbox from "../Helpers/Selectbox";
import ImageContainer from "./ImageContainer";
import PrimaryButton from "../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../Helpers/Buttons/SecondaryButton";
import SizeItem from "./SizeItem";
import ZoomThumbnail from "./ZoomThumbnail";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

// function to format date
function formatDate(dateStr) {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const date = new Date(dateStr);
  const year = date.getFullYear().toString().slice(-2); // Get only the last two digits of the years
  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, "0"); // Ensure the day is two digits
  return `${month}/${day}/${year}`; // Format: "Mon-Day-Year"
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Assuming mobile screens are <= 768px
    };

    handleResize(); // Call once to set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export default function ProductView({
  collection,
  styleNumber,
  colorCode,
  fit,
  className,
  reportHandler,
}) {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    border: "1px solid #D0D0D0",
  };

  const itemStyle = {
    border: "1px solid #D0D0D0",
    padding: "10px",
    flexBasis: "300px",
    marginRight: "10px",
    marginBottom: "10px",
  };

  const NoImage =
    "https://maevn-images.s3.us-east-2.amazonaws.com/swatches/NoImage.jpg";

  const NoImageDetail =
    "https://maevn-images.s3.us-east-2.amazonaws.com/580x630/NoImage.jpg";

  // get data from API
  const dispatch = useDispatch();

  // get productDetail
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  // Maevn outlet's style numbers ====================================================================
  const styleNumbers = [
    1718,
    7318,
    1738,
    8728,
    8708,
    8308,
    7338,
    5308,
    1748,
    5208,
    7348, // EON
    1202,
    1102,
    9202,
    9102,
    9802,
    9302,
    1802,
    1302, // Blossom
    8101,
    2101,
    8102,
    2100,
    8100,
    2102, // Blossom signature
    1016,
    9626,
    1626,
    1026,
    1006,
    9016,
    9026,
    9006,
    8606, // Core
    8801,
    8802, // Smart
    9810, // Prints
    1767,
    9800,
  ];

  // Convert product?.styleNumber to number for comparison
  const styleNo = Number(product?.styleNumber);

  const isOutletStyle = styleNumbers.includes(styleNo);
  // =================================================================================================

  // convert special characters
  const decodedShortDescription = decodeHTMLEntities(product?.shortDescription);

  // long description
  const longDescriptionArray = product?.longDescription
    ? product.longDescription.split("*").filter((item) => item.trim())
    : [];

  // validate user
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;
  const navigate = useNavigate();

  // permissions
  const wishListPermission = userInfo?.result.user.permissions.WishList;
  const shoppingCartPermission = userInfo?.result.user.permissions.ShoppingCart;
  const placeOrderPermission = userInfo?.result.user.permissions.PlaceOrder;

  // zoom window =====================================================================================
  // get images
  const [src, setSrc] = useState(NoImage);
  const [zoomSrc, setZoomSrc] = useState(NoImageDetail);
  const [isZoomWindowOpen, setIsZoomWindowOpen] = useState(false);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [zoomState, setZoomState] = useState(0);

  // Function to try loading an image at a given index in zoom window
  const tryLoadImage = (index, direction = 1) => {
    const img = new Image();
    img.src = product.imageDetailLinks[index].zoominURL;

    img.onload = () => {
      setSrc(product.imageDetailLinks[index].basicURL);
      setZoomSrc(product.imageDetailLinks[index].zoominURL);
      setCurrentIndex(index);
      setPrevDisabled(index === 0);
      setNextDisabled(index === product.imageDetailLinks.length - 1);
      return true;
    };

    img.onerror = () => {
      // If image fails to load, try the next one in the given direction
      let newIndex = (index + direction) % product.imageDetailLinks.length;
      if (newIndex < 0) newIndex = product.imageDetailLinks.length - 1; // Handle negative index
      if (newIndex !== index) {
        return tryLoadImage(newIndex, direction);
      }
      return false;
    };

    return true;
  };

  const imgElementRef = useRef(null);

  useEffect(() => {
    if (imgElementRef.current) {
      // Apply initial sizing logic on first render
      const imgElement = imgElementRef.current;
      imgElement.style.transform = `none`;
      imgElement.style.width = "auto";
      imgElement.style.height = "100%"; // Fit to container's height
      imgElement.style.maxWidth = "100%"; // Ensure it doesn't overflow horizontally
      imgElement.style.maxHeight = "100%"; // Ensure it doesn't overflow vertically
      imgElement.style.objectFit = "contain"; // Ensure the image is fully visible
    }
  }, []);

  // magnify the image in zoomin window
  const handleImageClick = (e) => {
    const imgElement = e.target;

    if (zoomState === 0) {
      // First click: fixed resolution 1740 x 1890 pixels
      imgElement.style.transform = `none`;
      imgElement.style.width = "1740px";
      imgElement.style.height = "1890px";
      imgElement.style.maxWidth = "none";
      imgElement.style.maxHeight = "none";

      setZoomState(1); // Move to the next zoom level
    } else if (zoomState === 1) {
      // Second click: fixed resolution 2175 x 2362.5 pixels
      imgElement.style.transform = `none`;
      imgElement.style.width = "2175px";
      imgElement.style.height = "2362.5px";
      imgElement.style.maxWidth = "none";
      imgElement.style.maxHeight = "none";

      setZoomState(2); // Move to the next zoom level
    } else {
      // Reset to initial state (third click)
      imgElement.style.transform = `none`;
      imgElement.style.width = "auto";
      imgElement.style.height = "100%"; // Fit to container's height
      imgElement.style.maxWidth = "100%";
      imgElement.style.maxHeight = "100%";
      imgElement.style.objectFit = "contain";

      setZoomState(0); // Reset zoom state
    }
  };

  const openZoomWindow = () => {
    setIsZoomWindowOpen(true);
  };

  const closeZoomWindow = () => {
    setZoomState(0);
    setIsZoomWindowOpen(false);
    setIsZoomedIn(false);
  };

  const toggleZoom = () => {
    setIsZoomedIn(!isZoomedIn);
  };

  // change thumbnails in zoom window
  const changeImgHandler = (current, zoom, index) => {
    setSrc(current);
    setZoomSrc(zoom);
    setCurrentIndex(index);
    setZoomState(0);

    const img = new Image();
    img.src = zoom;

    img.onload = () => {
      // Image loaded successfully, no further action needed
      setPrevDisabled(index === 0);
      setNextDisabled(index === product.imageDetailLinks.length - 1);
    };

    img.onerror = () => {
      const totalImages = product?.imageDetailLinks?.length || 0;
      let newIndex = index;

      if (totalImages > 1) {
        // Try loading the next image in the sequence
        do {
          newIndex = (newIndex + 1) % totalImages;
        } while (newIndex !== index && !tryLoadImage(newIndex));

        // Update the disabled states after attempting to load the next image
        setPrevDisabled(newIndex === 0);
        setNextDisabled(newIndex === product.imageDetailLinks.length - 1);
      } else {
        // If there's only one image or all fail, fall back to a default image
        setSrc(NoImage);
        setZoomSrc(NoImageDetail);
        setPrevDisabled(true);
        setNextDisabled(true);
      }
    };
  };

  // Zoom in responsive ===================================================================================
  const isMobile = useIsMobile();

  // product info =========================================================================================
  // swatches
  const [selectedColor, setSelectedColor] = useState(colorCode);
  const [selectedColorName, setSelectedColorName] = useState("");

  // fit
  const [selectedFit, setSelectedFit] = useState();

  // price
  const [unitPrice, setUnitPrice] = useState();
  const [discountedPrice, setDiscountedPrice] = useState();

  // ETA tooltip
  const [showTooltip, setShowTooltip] = useState(false);

  // size
  const [selectedItemIdx, setSelectedItemIdx] = useState(0); // selectedItem==index
  const [itemSize, setItemSize] = useState("");

  // quantity
  const [quantity, setQuantity] = useState(1);

  // sales marks
  const [isPreorder, setIsPreorder] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [isNew, setIsNew] = useState(false);

  // quantity
  const [quantities, setQuantities] = React.useState([]);

  // alert for input box
  const [inputValue, setInputValue] = useState("");

  // dialog alert for backorder
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  // move to shopping cart
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState(userInfo.result.user.userId);

  // set selectedColor
  const handleSwatchClick = (color) => {
    setQuantities([]); // Reset the quantities when color is changed

    setSelectedColor(color);

    //Momentum Mens
    if (
      styleNumber === "5801" ||
      styleNumber === "5802" ||
      styleNumber === "5861" ||
      styleNumber === "5891" ||
      styleNumber === "5892"
    ) {
      navigate(`/MomentumMens/${styleNumber}/${color}/${selectedFit}`);
    } else {
      navigate(`/${collection}/${styleNumber}/${color}/${selectedFit}`);
    }
    console.log("collection Color", collection);
  };

  // set Fit
  const handleFitClick = (fit) => {
    setQuantities([]); // Reset the quantities when fit is changed
    setSelectedFit(fit);

    //Momentum Mens
    if (
      styleNumber === "5801" ||
      styleNumber === "5802" ||
      styleNumber === "5861" ||
      styleNumber === "5891" ||
      styleNumber === "5892"
    ) {
      navigate(`/MomentumMens/${styleNumber}/${selectedColor}/${fit}`);
    } else {
      navigate(`/${collection}/${styleNumber}/${selectedColor}/${fit}`);
    }

    console.log("collection Fit ", collection);
  };

  // set index for selectedItem
  const handleSizeClick = (index) => {
    setSelectedItemIdx(index);

    const item = product.sizes[index];
    setUnitPrice(item.unitPrice);

    const discountedAmount =
      (item.unitPrice * Math.abs(item.discountRate)) / 100;

    const roundup = Math.round(discountedAmount * 100) / 100;
    setDiscountedPrice(item.unitPrice - roundup);
  };

  // set checking entered quantity
  const handleQuantityChange = (
    e,
    pid,
    availableQuantity,
    isPreorderQty,
    discountRateQty
  ) => {
    const inputQuantity = parseInt(e.target.value, 10);

    if (Number.isNaN(inputQuantity) || inputQuantity < 0) {
      setQuantities((prevQuantities) =>
        prevQuantities.filter((item) => item.pid !== pid)
      );
      return;
    }

    if (inputQuantity === 0) {
      setDialogMessage("The minimum order quantity is 1 or more.");
      setDialogOpen(true);
    } else {
      if (inputQuantity > availableQuantity) {
        setDialogMessage(
          "You can place backorders for any remaining quantities that exceed the current orderable quantity. If you prefer not to place a backorder, kindly adjust the quantity of the item in your order to match the currently available stock."
        );
        setDialogOpen(true);
      }
      setQuantities((prevQuantities) => {
        const updatedQuantities = prevQuantities.filter(
          (item) => item.pid !== pid
        );
        updatedQuantities.push({
          pid,
          quantity: inputQuantity,
          isPreorderQty,
          discountRateQty,
        });
        return updatedQuantities;
      });
    }
  };

  // Styled Dialog Content
  const StyledDialogContent = styled(DialogContent)``;

  // Styled Dialog Actions
  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;

  // Styled Dialog with Paper Props
  const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 30px;
      border-radius: 10px;
      background: var(--Base-White, #fff);
    }
  `;

  // go to checkout
  const handleCheckOutClick = async (e) => {
    e.preventDefault();

    // Check if all quantities are null
    if (
      quantities.length === 0 ||
      quantities.every((item) => item.quantity === null)
    ) {
      setDialogMessage("Please enter the quantity you would like to order.");
      setDialogOpen(true);
    } else {
      dispatch(addToCart(quantities));

      navigate(`/cart`);
    }
  };

  // move to cart or stay here
  const handleStayHereClick = () => {
    setIsModalOpen(false);
  };

  const handleGoToCartClick = () => {
    navigate(`/cart`);
    setIsModalOpen(false);
  };

  // add item to cart
  const handleAddToCartClick = async (e) => {
    e.preventDefault();

    // Check if all quantities are null
    if (
      quantities.length === 0 ||
      quantities.every((item) => item.quantity === null)
    ) {
      setDialogMessage("Please enter the quantity you would like to order.");
      setDialogOpen(true);
    } else {
      try {
        await dispatch(addToCart(quantities));
        await dispatch(getCart(userId)); // Update qty of cart in header
        setIsModalOpen(true);
        setQuantities([]); // Clear quantities
      } catch (error) {
        console.error("Failed to add item to cart:", error);
      }
    }
  };

  useEffect(() => {
    const colorName = product?.currentColorName;
    setSelectedColorName(colorName);

    if (
      product?.sizes &&
      Array.isArray(product?.sizes) &&
      selectedItemIdx !== null
    ) {
      const item = product.sizes[selectedItemIdx];
      if (item.isPreorder === 1) {
        setIsPreorder(true);
      } else {
        setIsPreorder(false);
      }

      if (item.isNew === 1) {
        setIsNew(true);
      } else {
        setIsNew(false);
      }

      if (item.discountRate < 0) {
        // initial discount rate
        setDiscountRate(item.discountRate);

        // initial discount price
        const discountedAmount =
          (item.unitPrice * Math.abs(item.discountRate)) / 100;

        const roundup = Math.round(discountedAmount * 100) / 100;
        setDiscountedPrice(item.unitPrice - roundup);

        setUnitPrice(item.unitPrice);
      } else {
        setDiscountRate(0);
        setUnitPrice(item.unitPrice);
      }

      if (item.itemSize) {
        setItemSize(item.itemSize);
      } else {
        setItemSize("");
      }
    }
  }, [product, selectedItemIdx]);

  // set the default basic & zoom image
  useEffect(() => {
    if (product && product?.imageDetailLinks) {
      setSrc(product?.imageDetailLinks[0].basicURL);
      setZoomSrc(product?.imageDetailLinks[0].zoominURL);
      setCurrentIndex(0); // Reset the index when the product changes
    }
  }, [product]);

  // default fit
  useEffect(() => {
    if (product && product.fitList) {
      setSelectedFit(product?.currentFit); // if click fit button, it will call API and then re-render productDetail component
    }
  }, [product]);

  // call APIs for user authentication and detailProduct
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(
      detailProduct({
        stylenumber: styleNumber,
        colorcode: colorCode,
        fit: fit,
        product,
      })
    );

    console.log("product", product);
  }, [dispatch, navigate, redirect, userInfo, styleNumber, colorCode, fit]);

  return (
    <div
      className={`product-view w-full lg:flex justify-between ${
        className || ""
      }`}
    >
      <div data-aos="fade-right" className="lg:w-1/2 xl:mr-[70px] lg:mr-[50px]">
        <div className="w-full">
          {/* Original border line */}
          {/* <div className="w-full border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3"> */}
          <div className="w-full border border-white flex justify-center items-center overflow-hidden relative mb-3">
            {/* <div className="w-full h-[630px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
                <div className="w-full h-[630px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
              <img
              src={`${process.env.PUBLIC_URL}/assets/images/${src}`}
              alt=""
              className="object-contain"
              /> */}

            {/* image container (no zoom) */}
            {/* <div className="image-container">
              <img
                // src={`https://b2bwebimagetest.s3.us-east-2.amazonaws.com/584x600.jpg`}
                src={`${src}`}
                alt=""
                className="max-w-full h-auto object-contain"
                // className=" [580px] h-[630px] object-contain"
                // className="object-contain"
                onError={(event) => {
                  if (product) {
                    event.target.onerror = null; // prevent infinite error loop
                    event.target.src = `${NoImageDetail}`; // set fallback image
                  }
                }}
              />
            </div> */}

            {/* ==================================================== Image container - Inner Zoom ==================================================== */}
            <div
              className="image-container relative"
              onClick={() => !isMobile && openZoomWindow()}
            >
              <InnerImageZoom
                src={`${src}`}
                zoomSrc={`${zoomSrc}`}
                zoomType="hover"
                zoomPreload={true}
                onMouseEnter={() => setZoomSrc(zoomSrc)}
                onMouseLeave={() => setZoomSrc(NoImageDetail)}
              />
            </div>

            {/* Sale Mark */}
            {/*
             <div className="w-[80px] h-[80px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[30px] top-[30px]">
                <span>-50%</span>
              </div>
            */}
            {/* image should be changed when mark location and design is decided */}
            {isPreorder === true && isNew === true && discountRate < 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image2"
                  />
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image3"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === true && isNew === true && discountRate === 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image2"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === true && isNew === false && discountRate === 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === true && isNew === false && discountRate < 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image3"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === false && isNew === true && discountRate === 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === false && isNew === true && discountRate < 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image2"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
            {isPreorder === false && isNew === false && discountRate < 0 && (
              <>
                <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
                  <img
                    src={preOrderMark}
                    className="w-full h-full object-cover"
                    alt="Image1"
                  />
                </div>
                <div className="w-[90px] h-[90px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[450px] top-[30px]">
                  <span>20%</span>
                </div>
              </>
            )}
          </div>
          {/*   Thumbnails Image container (no zoom) */}
          <div className="flex flex-wrap" style={{ gap: "8px" }}>
            {product?.imageDetailLinks &&
              product?.imageDetailLinks.length > 0 &&
              product?.imageDetailLinks.map((img, index) => (
                <ZoomThumbnail
                  key={img.id}
                  img={img}
                  src={src}
                  changeImgHandler={(current, zoom) =>
                    changeImgHandler(current, zoom, index)
                  }
                  NoImage={NoImage}
                />
              ))}
          </div>
        </div>
      </div>

      {/* ==================================================== Zoom Window ======================================================== */}

      {!isMobile && isZoomWindowOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-40">
          <div className="relative w-full h-full p-4 bg-white rounded-lg flex flex-col">
            <span
              className="absolute top-2 right-3 text-gray-500 focus:outline-none text-4xl py-2 px-6 rounded-full cursor-pointer z-50"
              onClick={closeZoomWindow}
              style={{ zIndex: 1000 }}
            >
              &times;
            </span>

            {/* ==================================================== Zoom Image container ==================================================== */}

            <div
              className="zoom-modal-container flex justify-center items-center mx-16 "
              style={{
                width: "auto",
                height:
                  zoomState === 0
                    ? "100%"
                    : `${imgElementRef.current?.naturalHeight}px`,
                overflow: zoomState === 0 ? "auto" : "scroll", // Enable scroll when zoomed in
                cursor: zoomState === 2 ? "zoom-out" : "zoom-in",
                display: "flex", // Center the image within the container
                justifyContent: "center", // Horizontal centering
                alignItems: zoomState === 0 ? "center" : "start", // Align to start when zoomed in
                position: "relative", // Ensure it fits within the modal
              }}
            >
              <img
                src={zoomSrc}
                alt="Product"
                ref={imgElementRef}
                style={{
                  transition:
                    "transform 0.1s ease-in-out, transform-origin 0.1s ease-in-out",
                  transformOrigin: zoomState === 0 ? "top center" : "top", // Align transform origin based on zoomState
                  objectFit: zoomState === 0 ? "contain" : "unset", // Use 'unset' when zoomed in to show full resolution
                  height:
                    zoomState === 0
                      ? "100%"
                      : `${imgElementRef.current?.naturalHeight}px`, // Adjust height based on zoomState
                  width:
                    zoomState === 0
                      ? "auto"
                      : `${imgElementRef.current?.naturalWidth}px`, // Adjust width based on zoomState
                }}
                onClick={handleImageClick}
              />
            </div>

            {/* ==================================================== Thumbnails container in Zoom Window ==================================== */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* ==================================================== Previous Button ================================================ */}

              <button
                type="button"
                className="zoom-image-nav-button left"
                onClick={() => {
                  setZoomState(0);
                  let prevIndex = currentIndex - 1;
                  if (prevIndex < 0)
                    prevIndex = product.imageDetailLinks.length - 1; // Cycle to the first image
                  tryLoadImage(prevIndex, -1); // -1 indicates moving backwards
                }}
                style={{
                  opacity: 1,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                  borderRadius: "50%", // Ensure the button itself is also round if needed
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="#808080"
                  style={{
                    borderRadius: "50%", // Ensure the SVG is round
                    transition: "fill 0.3s ease",
                  }}
                  onMouseOver={(e) => {
                    if (!nextDisabled) e.currentTarget.style.fill = "#696969"; // Very light grey on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.fill = "#808080"; // Original grey color
                  }}
                >
                  <circle cx="12" cy="12" r="10" fill="none" />
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 12l4.5-4.5-1.527-1.5-5.973 6 5.973 6 1.527-1.5-4.5-4.5z" />
                </svg>
              </button>

              {/* ==================================================== Thumbnails List in Zoom Window ==================================== */}

              <div
                className="mt-4 flex justify-center space-x-2 "
                style={{
                  marginRight: "25px",
                  marginLeft: "25px",
                }}
              >
                {product?.imageDetailLinks?.map((img, index) => (
                  <ZoomThumbnail
                    key={img.id}
                    img={img}
                    src={src}
                    changeImgHandler={(current, zoom) =>
                      changeImgHandler(current, zoom, index)
                    }
                    NoImage={NoImage}
                  />
                ))}
              </div>

              {/* ==================================================== Next Button ==================================================== */}

              <button
                type="button"
                className="zoom-image-nav-button right"
                onClick={() => {
                  setZoomState(0);
                  let nextIndex = currentIndex + 1;
                  if (nextIndex >= product.imageDetailLinks.length)
                    nextIndex = 0;
                  tryLoadImage(nextIndex, 1); // 1 indicates moving forwards
                }}
                disabled={nextDisabled} // Disable if the current index is the last image
                style={{
                  opacity: 1,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                  borderRadius: "50%", // Ensure the button itself is also round if needed
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="#808080"
                  style={{
                    borderRadius: "50%", // Ensure the SVG is round
                    transition: "fill 0.3s ease",
                  }}
                  onMouseOver={(e) => {
                    if (!nextDisabled) e.currentTarget.style.fill = "#696969"; // Very light grey on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.fill = "#808080"; // Original grey color
                  }}
                >
                  <circle cx="12" cy="12" r="10" fill="none" />
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ==================================================== zooom window end ======================================================== */}

      <div className="flex-1">
        <div className="product-details w-full mt-10 lg:mt-0">
          <span
            data-aos="fade-up"
            className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
          >
            Style no. {product?.styleNumber}
          </span>
          <p data-aos="fade-up" className="text-xl font-bold text-qblack mb-4">
            {/*  {product?.shortDescription}  */}
            {decodedShortDescription}
          </p>

          {/*  <div
            data-aos="fade-up"
            className="flex space-x-[10px] items-center mb-6"
          >
            <div className="flex">
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <span className="text-[13px] font-normal text-qblack">
              6 Reviews
            </span>
                  </div> */}
          <div data-aos="fade-up" className="flex space-x-2 items-center mb-7">
            {/* <span className="text-sm font-500 text-qgray line-through mt-2">
              {product?.priceMin} - {product?.priceMax}
                  </span> */}
            {discountRate < 0 ? (
              <>
                <span
                  className="text-xl font-500 text-qgray line-through"
                  style={{
                    color: "var(--Body-txt-Grey, #474747)",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  ${unitPrice.toFixed(2)}
                </span>
                <span
                  className="text-2xl font-500 text-qred"
                  style={{
                    color: "var(--Body-txt-Grey, #474747)",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  ${discountedPrice.toFixed(2)}
                </span>
              </>
            ) : (
              <span
                className="text-2xl font-500 text-qred"
                style={{
                  color: "var(--Body-txt-Grey, #474747)",
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                ${unitPrice}
              </span>
            )}
          </div>

          <ul
            data-aos="fade-up"
            className="text-qgray text-sm text-normal mb-[30px] leading-7"
          >
            {longDescriptionArray.map((item) => (
              <li key={item.trim()}>* {item.trim()}</li>
            ))}
          </ul>

          <div data-aos="fade-up" className="colors mb-[30px]">
            <div>
              <span className="text-sm font-normal uppercase text-qgray inline-block">
                Fabric Content
              </span>
            </div>
            <div>
              <span className="text-sm font-normal text-qgray  inline-block">
                *&nbsp;{product?.fabricContent}
              </span>
            </div>
          </div>

          {/* Swatch containers */}
          <div data-aos="fade-up" className="colors mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              COLOR&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{selectedColor}&nbsp; (
              &nbsp;
              {selectedColorName}&nbsp;)
            </span>

            <div className="flex gap-2 flex-wrap">
              {product?.colorSwatches &&
                product?.colorSwatches.length > 0 &&
                product?.colorSwatches.map(
                  (
                    color // Detail images
                  ) => (
                    <img
                      src={`${color.colordotURL}`}
                      alt=""
                      onClick={() => handleSwatchClick(color.colorCode)}
                      className={`w-[40px] h-[40px] border border-qgray-border cursor-pointer object-contain  ${
                        selectedColor === color.colorCode
                          ? "border-2 border-[#04ADD1]"
                          : "border border-qgray-border"
                      } ${src !== color.colordotURL ? "opacity-100" : ""} `}
                      onError={(event) => {
                        event.target.onerror = null; // prevent infinite error loop
                        event.target.src = `${NoImage}`; // set fallback image
                      }}
                    />
                  )
                )}
            </div>
          </div>

          {/* Color swatch using css colorCode */}
          {/*
          <div data-aos="fade-up" className="colors mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              COLOR&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{selectedColor}
            </span>

            <div className="product-detail">
              <div className="swatches">
                {swatches.map((swatch) => (
                  <Swatch
                    key={swatch.id}
                    color={swatch.color}
                    active={swatch.color === activeSwatch}
                    onClick={() => handleSwatchClick(swatch.color)}
                  />
                ))}
              </div>
            </div>
          </div>
          */}

          {/* Fit containers */}
          <div data-aos="fade-up" className="colors mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              Fit&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{selectedFit}
            </span>
            <div data-aos="fade-up" className="mb-[30px]">
              {product?.fitList &&
                product.fitList.filter((val) => val !== null && val !== "")
                  .length > 0 && (
                  <div className={styles.fitButtonsContainer}>
                    {product.fitList
                      .filter((val) => val !== null && val !== "")
                      .map((val) => (
                        <button
                          key={val}
                          type="button"
                          onClick={() => handleFitClick(val)}
                          className={`${styles.fitButton} ${
                            selectedFit === val ? styles.selected : ""
                          }`}
                        >
                          {val}
                        </button>
                      ))}
                  </div>
                )}
            </div>
          </div>

          {/* Size containers */}
          <div data-aos="fade-up" className="product-size mb-[30px]">
            <div className="flex justify-between items-center mb-[14px]">
              {/* Size Label */}
              <span className="text-sm font-normal uppercase text-qgray inline-block">
                SIZE : {itemSize}
              </span>

              {/* Tooltip Section for What is ETA? */}
              <div className="flex items-center">
                <span className="eta-label mr-2">What are ETA and WSL?</span>
                <div
                  className="tooltip-icon"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6.6 9H5.4V5.4H6.6V9ZM6.6 4.2H5.4V3H6.6V4.2Z"
                      fill="#04ADD1"
                      fillOpacity="0.8"
                    />
                  </svg>
                  {showTooltip && (
                    <div className="tooltip">
                      <span className="tooltip-bold">ETA</span> stands for{" "}
                      <span className="tooltip-bold">
                        Estimated Time of Arrival.
                      </span>{" "}
                      It's the anticipated date when a product will be back in
                      stock or available for delivery.
                      <br />
                      <br />
                      <span className="tooltip-bold">WSL</span> stands for{" "}
                      <span className="tooltip-bold">While Supplies Last.</span>{" "}
                      The product is available only as long as the current
                      inventory lasts.
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* size container */}
            <div className="w-full" style={{ margin: "-5.5px" }}>
              <div
                className="size-container "
                style={{
                  background: "var(--Base-White, #FFF)",
                  flexShrink: 0,
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* product sizes */}
                  {product?.sizes &&
                  Array.isArray(product?.sizes) &&
                  product?.sizes.length > 0 ? (
                    product?.sizes
                      /* .filter(
                        (item) =>
                          // item.erpActiveFlag !== 90 &&
                          // item.erpActiveFlag !== 10 &&
                          item.b2bActiveFlag !== 1
                      )   */
                      .map((item, index) => (
                        <SizeItem
                          key={item.pid}
                          item={item}
                          index={index}
                          handleSizeClick={handleSizeClick}
                          handleQuantityChange={handleQuantityChange}
                          quantities={quantities}
                          selectedItemIdx={selectedItemIdx}
                          isOutletStyle={isOutletStyle}
                        />
                      ))
                  ) : product?.sizes === undefined ? null : (
                    <div>No sizes available</div>
                  )}
                </div>
              </div>

              {/* Backorder modal */}
              <StyledDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs" // Limit the width of the dialog
                fullWidth
              >
                <StyledDialogContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "9px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                        fill="rgba(4, 173, 209, 0.80)"
                      />
                    </svg>
                    <div
                      style={{
                        width: "225px",
                        color: "var(--Base-Black, #000)",
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        fontWeight: "500",
                      }}
                    >
                      Check before you go
                    </div>
                  </div>
                  <div
                    style={{
                      width: "325px",
                      marginTop: "15px",
                      color: "var(--Body-txt-Grey, #474747)",
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {dialogMessage}
                  </div>
                </StyledDialogContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <PrimaryButton
                    width="121px"
                    height="40px"
                    fontSize="16px"
                    onClick={() => setDialogOpen(false)}
                  >
                    Confirm
                  </PrimaryButton>
                </div>
              </StyledDialog>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="quantity-card-wrapper w-full flex items-center h-[50px] space-x-[10px] mb-[30px]"
          >
            {/* <div className="w-[120px] h-full px-[26px] flex items-center border border-qgray-border">
              <div className="flex justify-between items-center w-full">
                <button
                  onClick={decrement}
                  type="button"
                  className="text-base text-qgray"
                >
                  -
                </button>
                <span className="text-qblack">{quantity}</span>
                <button
                  onClick={increment}
                  type="button"
                  className="text-base text-qgray"
                >
                  +
                </button>
                  </div> 
            </div> */}
            {/* Wish list */}
            {/*
            {wishListPermission === 1 && (
              <div className="w-[60px] h-full flex justify-center items-center border border-qgray-border">
                <button type="button">
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                        stroke="#D5D5D5"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="square"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            )}  */}
            <div className="flex-1 h-full">
              <div className="flex w-full h-full space-x-2">
                {/*
                {placeOrderPermission === 1 &&
                  shoppingCartPermission === 1 && (
                   <button
                    type="button"
                    className="black-btn text-sm font-semibold w-1/2 h-full"
                    onClick={handleCheckOutClick}
                  >
                    Buy Now
                  </button>  

                    <SecondaryButton
                      width="49%"
                      height="auto"
                      fontSize="18px"
                      onClick={handleAddToCartClick}
                      // disabled={disabled}
                    >
                      Go To Cart
                    </SecondaryButton>
                  )} 
                */}
                {shoppingCartPermission === 1 && (
                  /* <button
                    type="button"
                    className="black-btn text-sm font-semibold w-1/2 h-full"
                    onClick={handleAddToCartClick}
                  >
                    Add To Cart
                  </button> */

                  <PrimaryButton
                    width="218px"
                    height="50px"
                    fontSize="18px"
                    onClick={handleAddToCartClick}
                    // disabled={disabled}
                  >
                    Add To Cart
                  </PrimaryButton>
                )}
                <Dialog
                  open={isModalOpen}
                  onClose={handleStayHereClick}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="xs" // Limit the width of the dialog
                  fullWidth
                >
                  {/* <StyledDialogTitle id="alert-dialog-title">
                  Alert
                  </StyledDialogTitle> */}
                  <StyledDialogContent
                    style={{ paddingTop: "0", paddingBottom: "0" }}
                  >
                    <DialogContentText
                      id="alert-dialog-description"
                      style={{ textAlign: "center", lineHeight: "80px" }}
                    >
                      A new item has been added to your shopping Cart.
                    </DialogContentText>
                  </StyledDialogContent>

                  <StyledDialogActions
                    style={{ marginTop: "0", justifyContent: "flex-end" }}
                  >
                    {/* <button
                      type="button"
                      className="white-btn text-sm font-semibold w-1/4 h-10 bg-white text-black"
                      style={{ border: "1px solid black" }}
                      onClick={handleStayHereClick}
                    >
                      Keep Shopping
                    </button> */}
                    <SecondaryButton
                      width="180px"
                      height="40px"
                      fontSize="16px"
                      onClick={handleStayHereClick}
                      // disabled={disabled}
                    >
                      Keep Shopping
                    </SecondaryButton>
                    {/* <button
                      type="button"
                      className="black-btn text-sm font-semibold w-1/4 h-10 bg-black text-white"
                      onClick={handleGoToCartClick}
                    >
                      Go To Cart
                     </button> */}

                    <PrimaryButton
                      width="180px"
                      height="40px"
                      fontSize="16px"
                      onClick={handleGoToCartClick}
                      // disabled={disabled}
                    >
                      Go To Cart
                    </PrimaryButton>
                  </StyledDialogActions>
                </Dialog>
              </div>
            </div>
          </div>

          {/*
          <div data-aos="fade-up" className="mb-[20px]">
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Category :</span> Kitchen
            </p>
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Tags :</span> Beer, Foamer
            </p>
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">SKU:</span> KE-91039
            </p>
          </div>

          <div
            data-aos="fade-up"
            className="flex space-x-2 items-center mb-[20px]"
          >
            <span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0C0.247634 0 0.475436 0 0.729172 0C0.738324 0.160174 0.747477 0.316279 0.757647 0.493233C1.05816 0.392044 1.33885 0.282211 1.62818 0.203395C3.11296 -0.201361 4.51385 0.0366111 5.84202 0.779512C6.47661 1.13494 7.14171 1.39071 7.86987 1.47207C8.88125 1.58496 9.82093 1.35817 10.7098 0.88426C10.9335 0.765274 11.1522 0.636627 11.411 0.491199C11.4161 0.606117 11.4237 0.693577 11.4237 0.780529C11.4242 3.18822 11.4222 5.5954 11.4288 8.00309C11.4293 8.1892 11.3718 8.29089 11.2096 8.38039C9.31956 9.42279 7.4285 9.43499 5.54557 8.37734C4.06231 7.54443 2.55363 7.43307 0.992568 8.13835C0.804428 8.22327 0.737816 8.33005 0.739341 8.53904C0.749003 9.9206 0.744426 11.3027 0.744426 12.6842C0.744426 12.7849 0.744426 12.8851 0.744426 13C0.48764 13 0.254244 13 0 13C0 8.67582 0 4.34961 0 0Z"
                  fill="#EB5757"
                />
              </svg>
            </span>

            <button
              type="button"
              onClick={reportHandler}
              className="text-qred font-semibold text-[13px]"
            >
              Report This Item
            </button>
          </div>
          <div
            data-aos="fade-up"
            className="social-share flex  items-center w-full"
          >
            <span className="text-qblack text-[13px] mr-[17px] inline-block">
              Share This
            </span>

            <div className="flex space-x-5 items-center">
              <span>
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 16V9H0V6H3V4C3 1.3 4.7 0 7.1 0C8.3 0 9.2 0.1 9.5 0.1V2.9H7.8C6.5 2.9 6.2 3.5 6.2 4.4V6H10L9 9H6.3V16H3Z"
                    fill="#3E75B2"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 11.4 2.1 14.3 5.1 15.4C5 14.8 5 13.8 5.1 13.1C5.2 12.5 6 9.1 6 9.1C6 9.1 5.8 8.7 5.8 8C5.8 6.9 6.5 6 7.3 6C8 6 8.3 6.5 8.3 7.1C8.3 7.8 7.9 8.8 7.6 9.8C7.4 10.6 8 11.2 8.8 11.2C10.2 11.2 11.3 9.7 11.3 7.5C11.3 5.6 9.9 4.2 8 4.2C5.7 4.2 4.4 5.9 4.4 7.7C4.4 8.4 4.7 9.1 5 9.5C5 9.7 5 9.8 5 9.9C4.9 10.2 4.8 10.7 4.8 10.8C4.8 10.9 4.7 11 4.5 10.9C3.5 10.4 2.9 9 2.9 7.8C2.9 5.3 4.7 3 8.2 3C11 3 13.1 5 13.1 7.6C13.1 10.4 11.4 12.6 8.9 12.6C8.1 12.6 7.3 12.2 7.1 11.7C7.1 11.7 6.7 13.2 6.6 13.6C6.4 14.3 5.9 15.2 5.6 15.7C6.4 15.9 7.2 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z"
                    fill="#E12828"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0722 1.60052C16.432 1.88505 15.7562 2.06289 15.0448 2.16959C15.7562 1.74278 16.3253 1.06701 16.5742 0.248969C15.8985 0.640206 15.1515 0.924742 14.3335 1.10258C13.6933 0.426804 12.7686 0 11.7727 0C9.85206 0 8.28711 1.56495 8.28711 3.48557C8.28711 3.7701 8.32268 4.01907 8.39382 4.26804C5.51289 4.12577 2.9165 2.73866 1.17371 0.604639C0.889175 1.13814 0.71134 1.70722 0.71134 2.34742C0.71134 3.5567 1.31598 4.62371 2.27629 5.26392C1.70722 5.22835 1.17371 5.08608 0.675773 4.83711V4.87268C0.675773 6.5799 1.88505 8.00258 3.48557 8.32268C3.20103 8.39382 2.88093 8.42938 2.56082 8.42938C2.34742 8.42938 2.09845 8.39382 1.88505 8.35825C2.34742 9.74536 3.62784 10.7768 5.15722 10.7768C3.94794 11.7015 2.45412 12.2706 0.818041 12.2706C0.533505 12.2706 0.248969 12.2706 0 12.2351C1.56495 13.2309 3.37887 13.8 5.37062 13.8C11.8082 13.8 15.3294 8.46495 15.3294 3.84124C15.3294 3.69897 15.3294 3.52113 15.3294 3.37887C16.0052 2.9165 16.6098 2.31186 17.0722 1.60052Z"
                    fill="#3FD1FF"
                  />
                </svg>
              </span>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  );
}
